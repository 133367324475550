.paperContainer {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    height: fit-content;
}

.flexContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}

.flexContainerCenter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}

.flexContainerVertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.warningColor {
    color: var(--errorColor);
}

.highlighted {
    background-color: var(--secondaryColor) !important;
}

.workInProgress {
    opacity: 0.4;
}

.errorButton {
    color: white !important;
    background-color: var(--errorColor) !important;
    border: 1px solid var(--errorColor) !important;
}

.successButton {
    color: white !important;
    background-color: var(--successColor) !important;
    border: 1px solid var(--successColor) !important;
}