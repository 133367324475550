.eventSlotCreationContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 0 auto;
}

.eventsInput {
    width: 250px;
}

.timeslotPaper {
    min-width: 70% !important;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: fit-content;
}

.timeslotPaperInner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: fit-content;
}

.upcomingEventsBox {
    padding: 10px;
    margin-bottom: 15px;
    margin-right: 15px;
}

.eventNameTimeslot {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
}

.eventListEntryMainInfoOutter {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.eventListEntryMainInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.eventSlotList {
    display: flex;
    margin-top: 7px;
    margin-bottom: 7px;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.eventSlotListRight {
    display: flex;
    flex-wrap: wrap;
}

.eventSlotListLeft {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.upcomingEventsFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.upcomingEventsHeadline {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.eventCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.eventNonSwitchCenter {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}