.loginBox {
    max-width: 724px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.loginPic{
    background-image: url(../../Images/loginBackground.webp);
    background-size: cover;
}

.mail-login {
    width: 40% !important;
    min-width: 300px !important;
}

.pw-login {
    width: 40% !important;
    min-width: 300px !important;
}

.loginButton{
    background-color: var(--primaryColor) !important;
    color: white !important;
}

.padding{
    padding: 20px;
}

.verticalFloatLogin{
    display: flex;
    justify-content: space-between;
}