.outterBoxMember {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.innerFlexMember {
    display: flex;
    align-items: center;
    width: 80%;
    flex-wrap: wrap;
    gap: 20px;
}

.leftRightFlex {
    display: flex;
}

.outterBoxAddMember {
    width: 100%;
    background-color: gray !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.horizontalMemberFloat {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}


.dialogFlex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
}

.deleteMemberButton {
    background-color: var(--errorColor) !important;
    color: white !important;
}

.spacedOutFlexMemberEdit {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.checkboxFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center
}

.checkboxPaper {
    background-color: var(--secondaryColor) !important;
    margin-left: 10px;
    margin-bottom: 10px;
}

.checkboxFormControll {
    padding-left: 10px;
}

.searchBoxTrainer {
    width: 80%;
}

.sportProgress {
    min-width: 100px;
    margin-right: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.sportAlignDesktop {
    position: absolute;
    width: 100%;
    display: flex;
}