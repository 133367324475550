.reasonBox {
    width: 50% !important;
    min-width: 150px !important;
}

.reasonBoxRequest {
    width: 100% !important;
}

.workTimeBox {
    width: 20%;
    min-width: 120px !important;
    max-width: 200px !important;
}

.sportWarning {
    color: var(--errorColor);
    font-weight: bold;
}

.outterAddWorkTimeFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.innerAddWorkTimeFlex {
    display: flex;
    width: fit-content;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
}