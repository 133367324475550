.settingsBox {
    padding: 20px !important;
    width: fit-content !important;
    /*background-color: var(--secondaryColor);*/
}

.cardFloat {
    display: flex;
}

.checkboxColor {
    color: var(--primaryColor) !important;
}

.horizontalFloat {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.verticalFloat {
    display: flex;
    flex-direction: column;
    align-items: center;
}