.overviewContainer {
    width: 40%;
    min-width: 300px;
    background-color: var(--secondaryColor) !important;
    padding: 10px;
    margin-bottom: 10px;
}

.overviewEventsContainer {
    width: 40%;
    min-width: 300px;
    padding: 10px;
    margin-bottom: 10px;
}

.fullscreenCard {
    width: 40%;
    min-width: 600px;
    min-height: 300px;
}

.flexHorizontalSpaced {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.warningColor {
    color: var(--errorColor);
}

.backgroundCardImage {
    background-image: url(../../Images/loginBackground.webp);
    background-size: cover;
    width: 40%;
    min-width: 600px;
    min-height: 300px;
}

.imageTextWidth {
    max-width: 300px;
}

.overviewEventEntry:hover {
    cursor: pointer;
}