.requestBox {
    width: 100%;
    margin-bottom: 5px;
    padding: 10px;
}

.selectBox {
    width: 20%;
    min-width: 100px !important;
    max-width: 200px !important;
}

.outterFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.innerFlex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.border {
    margin-right: 10px !important;
}

.accept {
    background-color: var(--successColor) !important;
    color: white !important;
}

.deny {
    background-color: var(--errorColor) !important;
    color: white !important;
}

.close {
    background-color: lightgray !important;
    color: black !important;
}

.reasonRequestText {
    width: 20%;
    min-width: 100px !important;
    max-width: 200px !important;
}

.requestButtonFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}