.outterBoxSport {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.outterAddBoxSport {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.innerBoxSportLeft {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
    width: 80%;
}

.innerBoxSportRight {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 80%;
    justify-content: flex-end;
}

.denyBackground {
    color: var(--errorColor);
}

.successBackground {
    color: var(--successColor);
}

.fixedButton {
    position: fixed !important;
    bottom: 50px;
    right: 50px;
}